import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "../../App";
import { ThemeContext } from "../../context";
import { Path } from "../../enums/routes/Path";
import CheckoutDialog from "../dialog/CheckoutDialog";

const MainView: React.FC = () => {
  const { theme } = React.useContext(ThemeContext);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme === "dark" ? "dark" : "light"}
      />
        <div className="absolute right-0 top-0 mr-4 md:mr-6 mt-6">
          {/*<Toggle />*/}
        </div>
        <CheckoutDialog />
        <BrowserRouter>
          <Routes>
            <Route path={Path.BASE} element={<App />} />
          </Routes>
        </BrowserRouter>
    </>
  );
};

export default MainView;
