import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Web3Provider } from "./context/hooks/web3Hook";
import { Web3ProviderInstance } from "./context";
import ThemeProvider from "./context/provider/themeProvider";
import "react-toastify/dist/ReactToastify.css";
import MainView from "./components/views/MainView";
import VisibilityProvider from "./context/provider/visibilityProvider";
import PaymentProvider from "./context/provider/paymentProvider";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <VisibilityProvider>
        <PaymentProvider>
          <Web3Provider value={Web3ProviderInstance}>
            <MainView />
          </Web3Provider>
        </PaymentProvider>
      </VisibilityProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
