import creditcard from "../../assets/creditcard.png";

const StandardPaymentButton: React.FC = () => {
  return (
    <>
      <button className="bg-black hover:bg-gray-600 text-white rounded-md px-16 py-2 w-full flex inline-flex items-center justify-center gap-2">
        <img src={creditcard} alt="creditcard" className="h-8 w-9" />
        <p>Credit Card</p>
      </button>
    </>
  );
};

export default StandardPaymentButton;
