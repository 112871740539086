import { CheckCircleIcon } from "@heroicons/react/solid";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { ExclamationIcon } from "@heroicons/react/solid";
import { PaymentContext } from "../../context";
import { useContext } from "react";
import { TX_CHECK } from "../../environment/environment";

type PaymentStatusProps = {
	status: number;
};

const PaymentStatus = ({ status }: PaymentStatusProps) => {
	const { payment } = useContext(PaymentContext);

	const statuses = {
		SUCCESS: {
			title: "Payment Completed",
			subTitle: "You already paid these items",
			icon: <CheckCircleIcon color="green" width={80} height={80} />,
		},
		FAILURE: {
			title: "Payment Failed",
			subTitle: "Something went wrong, try again.",
			icon: <ExclamationCircleIcon color="red" width={80} height={80} />,
		},
		CLOSED: {
			title: "Payment Closed",
			subTitle: payment ? (
				<a
					className="p-1 w-full hover:underline rounded-md text-blue-500"
					href={TX_CHECK + payment?.txHash}
					target="_blank"
					rel="noreferrer"
				>
					You can see your transaction here
				</a>
			) : (
				<></>
			),
			icon: <CheckCircleIcon color="green" width={80} height={80} />,
		},
	};

	return (
		<>
			<div className="flex flex-col gap-3 text-center justify-center bg-gray-50 rounded-lg shadow-md p-4">
				<h1 className="font-bold text-xl ">
					{Object.values(statuses)[status].title}
				</h1>
				<div className="flex justify-center w-full">
					{Object.values(statuses)[status].icon}
				</div>

				<div className="text-center text-gray-500">
					<h3>{Object.values(statuses)[status].subTitle}</h3>
				</div>
			</div>
		</>
	);
};

export default PaymentStatus;
