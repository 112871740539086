import ApplePayButton from "../buttons/ApplePayButton";
import GPayButton from "../buttons/GPayButton";
import StandardPaymentButton from "../buttons/StandardPaymentButton";
import FlexContainer from "../theme/FlexContainer"

const StandardMethodsView = () => {
  return (
    <FlexContainer>
      <div className="shadow-xl w-1/4 bg-gray-50 rounded-lg p-8 flex flex-col space-y-4 items-center">
        <div className="w-auto flex flex-col items-center mb-5">
          <h1 className="font-bold text-2xl">Pay with </h1>
        </div>
        <GPayButton />
        <ApplePayButton />
        <StandardPaymentButton />
      </div>
    </FlexContainer>
  );
};

export default StandardMethodsView;
