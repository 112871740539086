import axios from "axios";
import { StorageKeys } from "../enums/storageKeys/storageKeys";
import { BASE_URL } from "../environment/environment";
import { ClosePaymentPayload } from "../models/ClosePaymentPayload";
import { Merchant } from "../models/Merchant";
import { Payment } from "../models/Payment";

export const PaymentService = {
  async getPayment(id: string): Promise<Payment> {
    console.log("getPayment id: ", id);
    const payment: Payment = await (
      await axios.get(`${BASE_URL}/payment/${id}`)
    ).data.payload;
    const merchant = await this.getMerchant(payment.merchantId);
    const total = this.calcTotal(payment);
    sessionStorage.setItem(StorageKeys.PAYMENT_ID, payment._id);
    return { ...payment, merchant: merchant, amount: total };
  },
  async getSavedPayment(): Promise<Payment> {
    const savedId = sessionStorage.getItem(StorageKeys.PAYMENT_ID);
    if (savedId) {
      return await this.getPayment(savedId);
    } else {
      throw new Error("Invalid id");
    }
  },
  async getMerchant(id: string): Promise<Merchant> {
    return await (
      await axios.get(`${BASE_URL}/merchant/${id}`)
    ).data.payload;
  },
  async closePayment(payload: ClosePaymentPayload): Promise<Payment> {
    const closedPayment: Payment = await (
      await axios.put(`${BASE_URL}/payment/${payload.paymentId}/close`, payload)
    ).data.payload;
    const total = this.calcTotal(closedPayment);
    return { ...closedPayment, amount: total };
  },
  calcTotal(payment: Payment): number {
    const prices = payment.cart.map((item) => item.price);
    const total = prices.reduce((prev, curr) => prev + curr, 0);
    return total;
  },
  async setTransactionHash(id: string, txHash: string) : Promise<Payment>
  {
    const ongoingPayment: Payment = await (
    await axios.put(`${BASE_URL}/payment/${id}/txHash`, {paymentId: id, txHash: txHash})
    ).data.payload;
    return { ...ongoingPayment};
  },
};
