import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";
import { PaymentContext } from "../../context";
import { Currency } from "../../enums/currency/Currency";
import { Chain } from "../../models/Chain";
import { ConversionService } from "../../services/ConversionService";

type CurrencyButtonProps = {
  chain: Chain;
};

const CurrencyButton = (props: CurrencyButtonProps) => {
  const { chain } = props;
  const { payment } = useContext(PaymentContext);
  const [convertedPrice, setPrice] = useState<number>();

  async function getConversion() {
    const conversion =
      payment &&
      (await ConversionService.convert(
        Currency.EUR,
        chain.coingeckoId,
        payment?.amount
      ));
    if (conversion) setPrice(conversion);
  }

  useEffect(() => {
    getConversion();
    //eslint-disable-next-line
  }, [chain, payment]);

  return (
    <>
      <RadioGroup.Option
        key={chain.name}
        value={chain.name}
        className="w-full cursor-pointer"
        style={{ width: "100% !important" }}
      >
        {({ active, checked }) => (
          <div
            className={`p-4 rounded-lg w-full text-black text-sm  ${
              checked
                ? `bg-gray-300`
                : "border-none hover:bg-gray-300 opacity-50"
            }`}
          >
            <div className="grid grid-cols-2 gap-15">
              <div className="flex flex-row gap-3 md:gap-0 ">
                <div className="h-full flex flex-wrap justify-start content-center items-start">
                  <img
                    src={chain.logo}
                    alt="chainlogo"
                    className="h-8 w-8 ml-2"
                  />
                </div>
                <div className="flex flex-col content-start items-start ml-4">
                  <h1 className="font-bold ">{chain.name}</h1>
                  <h2>{chain.currency}</h2>
                </div>
              </div>
              <div className="h-full w-full grid grid-cols-2 items-center justify-start">
                <div>
                  <h2 className="font-medium ">
                    {chain && convertedPrice
                      ? ConversionService.formatPrice(convertedPrice)
                      : "Converting..."}{" "}
                    {convertedPrice && chain.currency}
                  </h2>
                </div>
                <div className="justify-self-end">
                  {checked && <CheckIcon className="h-5 w-5 " />}
                </div>
              </div>
            </div>
          </div>
        )}
      </RadioGroup.Option>
    </>
  );
};

export default CurrencyButton;
