import UseAnimations from "react-useanimations";
import alertOctagon from "react-useanimations/lib/alertOctagon";

const FailureOutput = () => {
  return (
    <>
      <div className="shadow-xl bg-gray-50 rounded-lg p-8 flex flex-col space-y-4 items-center">
        <UseAnimations animation={alertOctagon} size={100} strokeColor="red" />
        <h1 className="font-bold text-3xl text-red-600 ">
          Transaction failed!
        </h1>
      </div>
    </>
  );
};

export default FailureOutput;
