import { ExclamationCircleIcon } from "@heroicons/react/solid";

const ErrorPage = () => {
  return (
    <>
      <div className="flex flex-col gap-3 items-center bg-gray-50 rounded-lg shadow-md p-8">
        <div className="w-full text-center">
          <h1 className="font-bold text-xl">Error</h1>
        </div>
        <div className="flex justify-center w-full">
          <ExclamationCircleIcon
            width={50}
            height={50}
            color={"red"}
          ></ExclamationCircleIcon>
        </div>
        <div className="text-center text-gray-500">
          <h3>
            There's been a problem retrieving data, <br></br> please check the
            link or try again
          </h3>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
