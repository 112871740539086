import { useContext, useEffect, useState } from "react";
import CheckoutLoader from "../loader/CheckoutLoader";
import CartRecap from "../buttons/CartRecap";
import { PaymentContext, VisibilityContext } from "../../context";
import PaymentClosed from "../payment-closed/PaymentStatus";
import { TransactionStatusEnum } from "../../enums/transactionStatus/TransactionStatusEnum";
import CryptopayLogo from "../../assets/cryptopaylogo.svg";
import FlexContainer from "../theme/FlexContainer";
import { Path } from "../../enums/routes/Path";
import ChooseCurrency from "../molecules/ChooseCurrency";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { ErrorMessages } from "../../enums/errors/ErrorMessages";
import { Chains } from "../../enums/chains/Chains";
import { useNavigate } from "react-router-dom";
import { supportedChains } from "../../demo/SupportedChains";
import { useWeb3 } from "../../context/hooks/web3Hook";
import { toast } from "react-toastify";
import { ModalMessages } from "../../enums/messages/ModalMessages";
import classNames from "classnames";

const ChooseCurrencyView = () => {
	const web3 = useWeb3();
	const navigate = useNavigate();
	const { payment, status, setQrMode, qrMode } = useContext(PaymentContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [loaderMessage, setLoaderMessage] = useState<string>();
	const [loaderSecondaryMessage, setLoaderSecondaryMessage] =
		useState<string>();
	const { setShow, setLeadingText, setBodyText } =
		useContext(VisibilityContext);

	useEffect(() => {
		setQrMode(window.location.href.includes(Path.QR_PAYMENT));
		//eslint-disable-next-line
	}, []);

	const showDialog = (leadingText: string, bodyText: string) => {
		setLeadingText(leadingText);
		setBodyText(bodyText);
		setShow(true);
	};

	const metamaskConnect = async () => {
		setLoaderMessage(ModalMessages.CONNECTING);
		setLoaderSecondaryMessage(ModalMessages.CHECK_METAMASK);
		setLoading(true);
		//navigate(`${Path.CONFIRMPAYMENT}/${Chains.ETHEREUM}`);
		await web3
			.sign(Math.random().toString())
			.then((v) => {
				navigate(`${Path.CONFIRM_PAYMENT}/${Chains.ETHEREUM}`);
			})
			.catch((err) => {
				toast.info(`${err.message}`, {
					type: "error",
				});
				if (err.message === ErrorMessages.MISSING_METAMASK) {
					showDialog(
						ModalMessages.WARNING,
						ModalMessages.MISSING_METAMASK
					);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleChainSelection = async (selectedChain: string) => {
		const chain = supportedChains.find(
			(elem) => elem.name === selectedChain
		);
		chain?.implemented
			? await metamaskConnect()
			: showDialog(
					ModalMessages.NOT_IMPLEMENTED,
					ModalMessages.NOT_IMPLEMENTED_BODY
			  );
	};

	const renderPaymentClosed = (status: TransactionStatusEnum) => {
		switch (status) {
			case TransactionStatusEnum.SUCCESS:
				return <PaymentClosed status={0} />;
			case TransactionStatusEnum.FAILURE:
				return <PaymentClosed status={1} />;
			case TransactionStatusEnum.CLOSED:
				return <PaymentClosed status={2} />;
		}
	};

	return loading ? (
		<>
			<CheckoutLoader
				loading={loading}
				message={loaderMessage}
				secondaryMessage={loaderSecondaryMessage}
			/>
		</>
	) : (
		<FlexContainer className={`${qrMode ? "items-start" : "items-center"}`}>
			<div
				className={classNames(
					"flex flex-col items-center mt-10 md:mt-0",
					{
						"w-screen": qrMode,
					}
				)}
			>
				{qrMode && (
					<div className="flex flex-col h-/5 w-screen justify-start items-center">
						<div>
							<img
								src={CryptopayLogo}
								alt="Cryptopay Logo"
								className="h-24 mt-8"
							/>
						</div>
						<div>
							<p className="mt-2 text-black text-2xl">
								{payment?.merchant.name}
							</p>
						</div>
						<div className="block p-6 max-w-sm">
							<h1 className="text-cryptopay-green text-center font-bold text-2xl">
								Total Amount
							</h1>
							<p className="text-black text-center font-bold text-xl mt-1">
								{payment?.amount} €
							</p>
						</div>
					</div>
				)}
				{/*Back button*/}
				{!qrMode && (
					<div className="w-11/12 content-start ml-2 md:ml-20">
						<button className="transform transition ease-in-out hover:scale-110">
							<div className="flex flex-row content-center items-center gap-2 text-lg">
								<ArrowLeftIcon className="h-5" />
								<p className="text-black text-xl">
									{payment?.merchant.name}
								</p>
							</div>
						</button>
					</div>
				)}
				{/*Page body */}
				<div
					className={classNames(
						"grid grid-cols-1 w-11/12 mt-5 mb-5",
						{
							"md:grid-cols-2 md:gap-2 w-max md:mt-none md:mb-none":
								!qrMode,
						}
					)}
				>
					{!qrMode && <CartRecap />}
					{renderPaymentClosed(status!)}
					{!status && (
						<ChooseCurrency
							handleChainSelection={handleChainSelection}
							showDialog={showDialog}
						/>
					)}
				</div>
			</div>
		</FlexContainer>
	);
};

export default ChooseCurrencyView;
