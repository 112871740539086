import { Chains } from "../enums/chains/Chains";
import { Currency } from "../enums/currency/Currency";
import { Chain } from "../models/Chain";
import ethereum from "../assets/ethereum.svg";
import bitcoin from "../assets/bitcoin.svg";
import tether from "../assets/tetherlogo.svg"
import polygon from "../assets/polygonlogo.svg"
import cryptopay from "../assets/cryptopaylogo.svg"

export const supportedChains: Chain[] = [
  {
    name: Chains.ETHEREUM,
    currency: Currency.ETH,
    logo: ethereum,
    mainColor: "gray-500",
    secondaryColor: "blue-700",
    implemented: true,
    coingeckoId: "ethereum"
  },
  {
    name: Chains.BITCOIN,
    currency: Currency.BTC,
    logo: bitcoin,
    mainColor: "yellow-700",
    secondaryColor: "blue-700",
    implemented: false,
    coingeckoId: "bitcoin"
  },
  {
    name: Chains.TETHER,
    currency: Currency.USDT,
    logo: tether,
    mainColor: "green-700",
    secondaryColor: "blue-700",
    implemented: false,
    coingeckoId: "tether"
  },
  {
    name: Chains.EUROTETHER,
    currency: Currency.EURT,
    logo: tether,
    mainColor: "green-700",
    secondaryColor: "blue-700",
    implemented: false,
    coingeckoId: "tether-eurt"
  },
  {
    name: Chains.POLYGON,
    currency: Currency.MATIC,
    logo: polygon,
    mainColor: "purple-700",
    secondaryColor: "blue-700",
    implemented: false,
    coingeckoId: "matic-network"
  },
  {
    name: Chains.CRYPTOPAY,
    currency: Currency.CRP,
    logo: cryptopay,
    mainColor: "green-700",
    secondaryColor: "blue-700",
    implemented: false,
    coingeckoId: "tether-eurt"
  },
];

export const QrSupportedChains: Chain[] = [
  {
    name: Chains.ETHEREUM,
    currency: Currency.ETH,
    logo: ethereum,
    mainColor: "gray-500",
    secondaryColor: "blue-700",
    implemented: true,
    coingeckoId: "ethereum"
  },
  {
    name: Chains.CRYPTOPAY,
    currency: Currency.CRP,
    logo: cryptopay,
    mainColor: "green-700",
    secondaryColor: "blue-700",
    implemented: false,
    coingeckoId: "tether-eurt"
  },
  {
    name: Chains.BITCOIN,
    currency: Currency.BTC,
    logo: bitcoin,
    mainColor: "yellow-700",
    secondaryColor: "blue-700",
    implemented: false,
    coingeckoId: "bitcoin"
  },
]

