import { useContext } from "react";
import { Web3Context, Web3Event } from "../index";
import { EventService } from "../provider/eventProvider";
import { CheckoutWeb3Provider } from "../provider/web3Provider";

export const useWeb3 = (): CheckoutWeb3Provider => {
  const context = useContext(Web3Context);
  if (context === undefined) {
    throw new Error("useWeb3() hook must be used within a provider");
  }
  return context;
};

export const useEventWeb3 = (): EventService => {
  const context = useContext(Web3Event);
  if (context === undefined) {
    throw new Error("useWeb3() hook must be used within a provider");
  }
  return context;
}

export const Web3Provider = Web3Context.Provider;
export const Web3EventProvide = Web3Event.Provider;
