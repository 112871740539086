import React, { ReactNode, ReactPortal, useEffect } from "react";
import { PaymentContext } from "..";
import { StorageKeys } from "../../enums/storageKeys/storageKeys";
import { TransactionStatusEnum } from "../../enums/transactionStatus/TransactionStatusEnum";
import { Payment } from "../../models/Payment";

type PaymentProviderProps = {
  children?: ReactNode | ReactPortal;
};

const PaymentProvider = (props: PaymentProviderProps) => {
  const [payment, setPayment] = React.useState<Payment>();
  const [status, setStatus] = React.useState<TransactionStatusEnum>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [qrMode, setQrMode] = React.useState<boolean>(false);

  useEffect(() => {
    if (payment) {
      sessionStorage.setItem(StorageKeys.PAYMENT_ID, payment._id);
    }
      // eslint-disable-next-line
  }, []);

  return (
    <PaymentContext.Provider
      value={{
        payment,
        status,
        qrMode,
        errorMessage,
        setQrMode,
        setPayment,
        setStatus,
        setErrorMessage,
      }}
    >
      {props?.children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
