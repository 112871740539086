import Checkmark from "react-typescript-checkmark";

const SuccessOutput = () => {
  return (
    <>
      <div className="shadow-xl bg-gray-50 rounded-lg p-8 flex flex-col space-y-4 items-center">
        <Checkmark size="xl" backgroundColor="green" />
        <h1 className="font-bold text-3xl text-green-600 text-center">
          Transaction successful!
        </h1>
      </div>
    </>
  );
};

export default SuccessOutput;
