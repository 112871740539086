import Web3 from "web3";
import { Contract } from "web3-eth-contract";
import { AbiItem } from "web3-utils";
import React, { createContext } from "react";
import ABI from "../../environment/contract_abi.json";
import { CONTRACT_ADDRESS } from "../../environment/environment";
import { ConversionService } from "../../services/ConversionService";
import { TransactionStatusEnum } from "../../enums/transactionStatus/TransactionStatusEnum";
import { PaymentService } from "../../services/PaymentService";
import { Payment } from "../../models/Payment";

export class CheckoutWeb3Provider {
  private readonly instance: Web3;
  private readonly contract: Contract;
  private readonly context: React.Context<Web3>;

  constructor() {
    this.instance = new Web3((window as any).ethereum);
    console.log("creating contract instance");
    this.contract = new this.instance.eth.Contract(
      ABI as AbiItem[],
      CONTRACT_ADDRESS
    );
    console.log(this.contract);
    this.context = createContext<Web3>(this.instance);
  }

  public async getCurrentAccount(): Promise<string> {
    await this.instance.eth.requestAccounts().then((res) => console.log(res));
    return (await this.instance.eth.getAccounts())[0];
  }

  public async sign(nonce: string): Promise<string> {
    const address = await this.getCurrentAccount();
    return await this.signWith(nonce, address);
  }
  //get info on current id
  public async getChainId(): Promise<number> {
    return await this.instance.eth.getChainId();
  }

  public async signWith(nonce: string, address: string): Promise<string> {
    return await this.instance.eth.personal.sign(nonce, address, nonce);
  }

  public toWei(numberString: string) {
    const formattedPrice = ConversionService.formatPrice(
      parseFloat(numberString)
    );
    return this.instance.utils.toWei(`${formattedPrice}`, "ether");
  }

  public toWeiNoFormatting(numberString: string) {
    const formattedPrice = ConversionService.formatPrice18(
      parseFloat(numberString)
    );
    return this.instance.utils.toWei(`${formattedPrice}`, "ether");
  }

  public fromWei(numberString: string) {
    return this.instance.utils.fromWei(numberString, "ether");
  }

  public async sendTransaction(
    _id: string,
    currency: string,
    coinName: string,
    value: string,
    setStatus: (status : TransactionStatusEnum) => (void),
    payment: Payment,
    setPayment: (payment : Payment) => (void)
  ) {
    const buyer = await this.getCurrentAccount();
    console.log(`buyer: ${buyer}`);
    return this.contract.methods
      .triggerPayment(_id, currency, coinName)
      .send({
        from: buyer,
        to: CONTRACT_ADDRESS,
        value: value,
      })
      .on("transactionHash", (hash: string) => {
        console.log(hash);
        payment.txHash = hash;
        setPayment(payment);
        setStatus(TransactionStatusEnum.CLOSED);
        PaymentService.setTransactionHash(_id, hash);
      });
  }
  }
