import { Routes, Route, useSearchParams } from "react-router-dom";
import "./App.css";
import ConfirmTransaction from "./components/views/ConfirmTransaction";
import ChooseCurrencyView from "./components/views/ChooseCurrencyView";
import StandardMethodsView from "./components/views/StandardMethodsView";
import { Path } from "./enums/routes/Path";
import TransactionStatus from "./components/views/TransactionStatus";
import { useContext, useEffect } from "react";
import { PaymentContext, VisibilityContext } from "./context";
import { PaymentService } from "./services/PaymentService";
import CheckoutLoader from "./components/loader/CheckoutLoader";
import ErrorPage from "./components/error/ErrorPage";
import { TransactionStatusEnum } from "./enums/transactionStatus/TransactionStatusEnum";
import { useEventWeb3 } from "./context/hooks/web3Hook";

function App() {
	const { isLoading, setIsLoading } = useContext(VisibilityContext);
	const { error, setError } = useContext(VisibilityContext);
	const { payment, setPayment, setStatus } = useContext(PaymentContext);
	const [searchParams] = useSearchParams();
	const eventWeb3 = useEventWeb3();

	const listenPayment = async () => {
		payment &&
			(await eventWeb3.subscribeToPayment(payment?._id, setStatus));
	};

	const getPayment = async () => {
		console.log("getPayment, payment: ", payment);
		setIsLoading(true);
		if (!payment) {
			const paymentId = searchParams.get("ref");
			if (paymentId) {
				await PaymentService.getPayment(paymentId)
					.then((payment) => {
						console.log({payment});
						setPayment(payment);
						if (payment.closed)
							setStatus(TransactionStatusEnum.SUCCESS);
						else if (payment.txHash !== undefined)
							setStatus(TransactionStatusEnum.CLOSED);
					})
					.catch((err) => {
						setError(true);
					});
			} else {
				await PaymentService.getSavedPayment()
					.then((payment) => {
						console.log(payment);
						setPayment(payment);
						if (payment.closed)
							setStatus(TransactionStatusEnum.SUCCESS);
						else if (payment.txHash !== undefined)
							setStatus(TransactionStatusEnum.CLOSED);
					})
					.catch((err) => {
						setError(true);
					});
			}
		}
		setIsLoading(false);
	};

	useEffect(() => {
		listenPayment();
		//eslint-disable-next-line
	}, [payment]);

	useEffect(() => {
		getPayment();
		//eslint-disable-next-line
	}, []);

	return !payment && isLoading ? (
		<CheckoutLoader loading={isLoading}></CheckoutLoader>
	) : !payment && error ? (
		<>
			<ErrorPage />
		</>
	) : (
		<>
			<Routes>
				<Route path={Path.DEFAULT} element={<ChooseCurrencyView />} />
				<Route
					path={Path.STANDARD_METHODS}
					element={<StandardMethodsView />}
				/>
				{/* <Route path={Path.CHOOSE_CRYPTO} element={<ChooseCurrencyView />} />*/}
				<Route
					path={`${Path.CONFIRM_PAYMENT}/:chainName`}
					element={<ConfirmTransaction />}
				/>
				<Route
					path={`${Path.CONFIRM_PAYMENT}/:chainName${Path.PAYMENT_STATUS}`}
					element={<TransactionStatus />}
				/>
				<Route
					path={Path.QR_PAYMENT}
					element={<ChooseCurrencyView />}
				/>
			</Routes>
		</>
	);
}

export default App;
