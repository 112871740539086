import applepaywhite from "../../assets/applepaywhite.svg";

const ApplePayButton: React.FC = () => {
  return (
    <>
      <button className="bg-black hover:bg-gray-600 text-white rounded-md w-full h-50 flex flex-col flex-wrap content-center">
        <img src={applepaywhite} alt="applepay" />
      </button>
    </>
  );
};

export default ApplePayButton;
