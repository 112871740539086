import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaymentContext } from "../../context";
import { useWeb3 } from "../../context/hooks/web3Hook";
import { Path } from "../../enums/routes/Path";
import { TransactionStatusEnum } from "../../enums/transactionStatus/TransactionStatusEnum";

const TransactionRecap = () => {
	const { payment, qrMode } = useContext(PaymentContext);
	const { status, errorMessage } = useContext(PaymentContext);
	const [address, setAddress] = useState<string>("Please wait...");
	const web3 = useWeb3();
	const navigate = useNavigate();

	const getAddress = async () => {
		await web3.getCurrentAccount().then((addr) => {
			setAddress(addr);
		});
	};

	useEffect(() => {
		getAddress();
		// eslint-disable-next-line
	}, []);

	return status !== TransactionStatusEnum.WAIT &&
		status !== TransactionStatusEnum.CLOSED ? (
		<>
			<div className="shadow-xl bg-gray-50 rounded-lg p-8">
				{status === TransactionStatusEnum.SUCCESS ? (
					<>
						<div className="flex flex-row gap-1 overflow-x-auto">
							<h3 className="font-bold mb-2">From:</h3>
							<h3 className="">{address}</h3>
						</div>
					</>
				) : (
					<>
						<div className="text-center text-gray-500">
							<h3>
								{errorMessage} <br></br> Please try again or
								contact the store owner
							</h3>
						</div>
					</>
				)}
				<button
					className="mt-4 w-full p-3 bg-blue-500 hover:bg-blue-700 text-white rounded-md "
					onClick={() => {
						qrMode
							? navigate(Path.QR_PAYMENT)
							: navigate(Path.DEFAULT);
					}}
				>
					<p>Back to store</p>
				</button>
			</div>
		</>
	) : (
		<></>
	);
};

export default TransactionRecap;
