import { useContext } from "react";
import { PaymentContext } from "../../context";
import CartRow from "../cart/CartRow";

const CartRecap = () => {
  const { payment } = useContext(PaymentContext);

  return (
    <>
      <div>
        <div className="w-auto rounded-lg p-1 md:p-8 flex flex-col space-y-2 content-start">
          <div className="w-full flex content-start mb-5 mt-3">
            <h1 className="font-medium text-2xl">Shopping cart</h1>
          </div>
          <div className="md:p-5">
            <div className="flex flex-col content-start mb-8 ml-8">
              <p className="text-gray-600">Pay {payment?.merchant.name}</p>
              <h1 className="text-black text-3xl mt-2">€ {payment?.amount}</h1>
            </div>
            <div className="grid grid-rows-4 gap-3 ml-8">
              {payment?.cart.map((prod) => {
                return <CartRow key={prod.name} product={prod} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartRecap;
