import { createContext } from "react";
import { TransactionStatusEnum } from "../enums/transactionStatus/TransactionStatusEnum";
import { Payment } from "../models/Payment";
import { EventService } from "./provider/eventProvider";
import { CheckoutWeb3Provider } from "./provider/web3Provider";

export const Web3ProviderInstance = new CheckoutWeb3Provider();
export const Web3Context =
  createContext<CheckoutWeb3Provider>(Web3ProviderInstance);
export const Web3EventInstance = new EventService();
export const Web3Event = createContext<EventService>(Web3EventInstance);

type ThemeName = "light" | "dark" | string;
type ThemeContextType = {
  theme: ThemeName;
  setTheme: (name: ThemeName) => void;
};
export const ThemeContext = createContext<ThemeContextType>(
  {} as ThemeContextType
);

type VisibilityContextType = {
  show: boolean;
  isLoading: boolean;
  error: boolean;
  leadingText: string;
  bodyText: string;
  setShow: (show: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setError: (error: boolean) => void;
  setLeadingText: (text: string) => void;
  setBodyText: (text: string) => void;
};
export const VisibilityContext = createContext<VisibilityContextType>(
  {} as VisibilityContextType
);

type PaymentContextType = {
  payment?: Payment;
  qrMode?: boolean;
  status?: TransactionStatusEnum;
  errorMessage?: string;

  setQrMode: (qrMode: boolean) => void;
  setPayment: (payment: Payment) => void;
  setStatus: (status: TransactionStatusEnum) => void;
  setErrorMessage: (msg: string) => void;
};
export const PaymentContext = createContext<PaymentContextType>(
  {} as PaymentContextType
);
