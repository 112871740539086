import { RadioGroup } from "@headlessui/react";
import { useContext, useState } from "react";
import { QrSupportedChains, supportedChains } from "../../demo/SupportedChains";
import { PaymentContext } from "../../context";
import { ModalMessages } from "../../enums/messages/ModalMessages";
import CurrencyButton from "../buttons/CurrencyButton";
import { COIN_GECKO_CAP } from "../../environment/environment";
import { ChartSquareBarIcon } from "@heroicons/react/outline";

type ChooseCurrencyProps = {
	handleChainSelection: (selected: string) => void,
	showDialog: (leadingText: string, bodyText: string) => void,
}

const ChooseCurrency = ({handleChainSelection, showDialog}: ChooseCurrencyProps) => {
	const [selected, setSelected] = useState<string>(supportedChains[0].name);
	const { qrMode } = useContext(PaymentContext);

	return (
		<>
			<div className="shadow-md w-auto h-3/12 bg-gray-50 rounded-lg p-5 flex flex-col items-center">
				<div className="flex flex-col space-y-2 items-center w-11/12">
					<div className="w-full flex flex-row justify-start mb-5">
						<h1 className="font-medium text-2xl">Pay with</h1>
					</div>
					<RadioGroup
						value={selected}
						onChange={setSelected}
						className="w-full"
					>
						<div className="space-y-1">
							{!qrMode &&
								supportedChains.map((chain) => (
									<CurrencyButton
										key={chain.name}
										chain={chain}
									/>
								))}
							{qrMode &&
								QrSupportedChains.map((chain) => (
									<CurrencyButton
										key={chain.name}
										chain={chain}
									/>
								))}
						</div>
					</RadioGroup>
					<div className="flex flex-row justify-end w-full">
						<button
							className="py-2 px-4 rounded-lg inline-flex gap-2 items-center justify-center hover:underline"
							onClick={() => window.open(COIN_GECKO_CAP)}
						>
							<ChartSquareBarIcon className="h-4 " />
							<span className="text-xs">
								Browse Crypto Price Chart
							</span>
						</button>
					</div>
					<button
						className="w-full p-3 bg-blue-500 hover:bg-blue-700 text-white rounded-md "
						onClick={() => {
							handleChainSelection(selected);
						}}
					>
						<p>Proceed with {selected}</p>
					</button>
					<p className="text-gray-400">or</p>
					<button
						className="p-1 w-full hover:underline rounded-md text-blue-500"
						onClick={() => {
							showDialog(
								ModalMessages.NOT_IMPLEMENTED,
								ModalMessages.NOT_IMPLEMENTED_BODY
							);
						}}
					>
						<p>Use a standard payment method</p>
					</button>

					<div className="flex items-center content-center text-sm text-gray-500">
						<p>Powered by</p>
						<p className="ml-1 font-bold"> CryptoPay</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default ChooseCurrency;
