import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Path } from "../../enums/routes/Path";
import { useState, useContext, useEffect } from "react";
import { PaymentContext } from "../../context";
import { useWeb3 } from "../../context/hooks/web3Hook";
import CheckoutLoader from "../loader/CheckoutLoader";
import { Chain } from "../../models/Chain";
import { supportedChains } from "../../demo/SupportedChains";
import { Chains } from "../../enums/chains/Chains";
import { Currency } from "../../enums/currency/Currency";
import { ConversionService } from "../../services/ConversionService";
import { TransactionStatusEnum } from "../../enums/transactionStatus/TransactionStatusEnum";
import { ErrorMessages } from "../../enums/errors/ErrorMessages";
import FlexContainer from "../theme/FlexContainer";

const ConfirmTransaction = () => {
	const navigate = useNavigate();

	const web3 = useWeb3();
	const [loading, setLoading] = useState<boolean>(false);

	let { chainName } = useParams<string>();
	const location = useLocation();
	const [chain, setChain] = useState<Chain>();
	const { payment, setPayment } = useContext(PaymentContext);
	const { status, setStatus, setErrorMessage, qrMode } =
		useContext(PaymentContext);
	const [price, setPrice] = useState<number>();

	const navHome = () => {
		// TODO save the context so it doesn't reset to false when the page is refreshed
		// TODO ha senso salvarlo lato server direttamente nell'oggetto che sta sul DB
		qrMode ? navigate(Path.QR_PAYMENT) : navigate(Path.DEFAULT);
	};

	async function getConversion() {
		if (chain) {
			const conversion =
				payment &&
				(await ConversionService.convert(
					Currency.EUR,
					chain.coingeckoId,
					payment?.amount
				));
			if (conversion) setPrice(conversion);
		}
	}

	async function getChain() {
		setChain(
			supportedChains.find((elem) => {
				return elem.name === chainName;
			})
		);
		await getConversion();
	}

	useEffect(() => {
		if (
			status === TransactionStatusEnum.SUCCESS ||
			status === TransactionStatusEnum.CLOSED
		)
			navigate(`${location.pathname}${Path.PAYMENT_STATUS}`);
		getChain();
		//eslint-disable-next-line
	}, [chain]);

	const submit = async () => {
		if (chain && price && payment) {
			setStatus(TransactionStatusEnum.WAIT);
			navigate(`${location.pathname}${Path.PAYMENT_STATUS}`);
			console.log(
				"wei sent to metamsk" + web3.toWeiNoFormatting(price.toString())
			);
			await web3
				.sendTransaction(
					payment._id,
					chain.currency,
					chain.name,
					web3.toWeiNoFormatting(price.toString()),
					setStatus,
					payment,
					setPayment
				)
				.then(() => {
					console.log("res.events");
					payment.closed = true;
					setPayment(payment);
					setStatus(TransactionStatusEnum.SUCCESS);
					// navigate(`${location.pathname}${Path.PAYMENTSTATUS}`);
				})
				.catch((err: { message: string | ErrorMessages[] }) => {
					console.log(err);
					setStatus(TransactionStatusEnum.FAILURE);
					setErrorMessage(
						err.message.includes(
							ErrorMessages.INVALID_ADDRESS_ERROR
						)
							? ErrorMessages.INVALID_ADDRESS_OUTPUT
							: ErrorMessages.GENERIC
					);
					// navigate(`${location.pathname}${Path.PAYMENTSTATUS}`);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const simulatePay = () => {
		setLoading(true);
		setTimeout(() => {
			navigate(`${location.pathname}${Path.PAYMENT_STATUS}`);
			setLoading(false);
		}, 2000);
	};

	const pay = async () => {
		if (chainName === Chains.ETHEREUM) {
			submit();
		} else {
			simulatePay();
		}
	};

	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(" ");
	}

	return loading ? (
		<>
			<CheckoutLoader
				loading={loading}
				message="Processing transaction..."
			/>
		</>
	) : (
		<FlexContainer>
			<div className="shadow-xl md:w-4/12 w-11/12 bg-gray-50 rounded-lg p-8 flex flex-col space-y-4 items-center">
				<h1 className="font-bold text-3xl">Confirm payment</h1>
				<img
					src={chain?.logo}
					alt="chainlogo"
					className="h-10 w-11 mt-6"
				/>
				<div className="flex inline-flex gap-x-2 py-3">
					<h1 className="font-bold">Amount to pay:</h1>
					<h1>
						{price
							? `${price} ${chain?.currency}`
							: "Converting..."}
					</h1>
				</div>
				<div className="w-full">
					<div
						className={classNames(
							"rounded-xl p-3",
							"focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60"
						)}
					>
						<button
							className={`bg-blue-500 hover:bg-${chain?.secondaryColor} rounded-md w-full p-3 text-white`}
							onClick={pay}
						>
							CONFIRM
						</button>
					</div>
				</div>

				<button
					className="w-full p-3 underline decoration-dotted hover:text-gray-500"
					onClick={navHome}
				>
					Back to Home
				</button>
			</div>
		</FlexContainer>
	);
};

export default ConfirmTransaction;
