import React, { useContext } from "react";
import { MoonLoader } from "react-spinners";
import { PaymentContext, ThemeContext } from "../../../context";
import { TransactionStatusEnum } from "../../../enums/transactionStatus/TransactionStatusEnum";
import { TX_CHECK } from "../../../environment/environment";

const OngoingOutput = () => {
	const { theme } = React.useContext(ThemeContext);
	const loading: boolean = true;
	const { status, payment } = useContext(PaymentContext);

	return (
		<>
			<div className="shadow-xl bg-gray-50 rounded-lg p-8 flex flex-col space-y-4 items-center">
				<MoonLoader
					loading={loading}
					color={theme === "dark" ? "#C4B6B6" : "#1F4068"}
				/>{" "}
				{status === TransactionStatusEnum.CLOSED ? (
					<>
						<h1 className="font-bold text-3xl text-gray-600 text-center">
							Your transaction is ongoing!
						</h1>
						{payment ? (
							<p>
								<a
									className="p-1 w-full hover:underline rounded-md text-blue-500"
									href={TX_CHECK + payment?.txHash}
									target="_blank"
									rel="noreferrer"
								>
									You can see your transaction here
								</a>
							</p>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default OngoingOutput;
