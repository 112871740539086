import GooglePayButton from "@google-pay/button-react";

const gButtonStyle: React.CSSProperties = {
  width: "100%",
  height: "50px",
};

const GPayButton: React.FC = () => {
  return (
    <>
      <GooglePayButton
        environment="TEST"
        buttonColor="default"
        buttonSizeMode="fill"
        buttonType="plain"
        style={gButtonStyle}
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["MASTERCARD", "VISA"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "example",
                  gatewayMerchantId: "exampleGatewayMerchantId",
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: "12345678901234567890",
            merchantName: "Demo Merchant",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: "100.00",
            currencyCode: "USD",
            countryCode: "US",
          },
        }}
      />
    </>
  );
};

export default GPayButton;
