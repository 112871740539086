type defaultWrapperProps = {
    className?: string
    children?: JSX.Element
}

const FlexContainer = ({className, children}: defaultWrapperProps) => {
    return (
        <div className={`${className} container mx-auto flex h-full w-screen max-w-full min-h-screen justify-center items-center bg-gray-100`}>
            {children}
        </div>
    )
}

export default FlexContainer