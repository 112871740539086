import { CartItem } from "../../models/CartItem";
import shoppingCart from "../../assets/shopping-cart.png";

type CartRowProps = {
  product: CartItem;
};

const CartRow = (props: CartRowProps) => {
  const { product } = props;
  return (
    <>
      <div className="my-2">
        <div className="w-auto grid grid-cols-3 content-center items-center">
          <div className="grid grid-cols-3 col-span-2">
            <img
              src={product.imageUrl ?? shoppingCart}
              alt="prodimage"
              className="rounded-md object-contain h-10"
            />
            <div className="flex flex-col h-full items-start justify-start col-span-2">
              <p className="flex-grow w-full font-semibold text-sm">
                {product.name}
              </p>
              <p className="text-black text-opacity-75 text-xs">
                Qty: {product.quantity}
              </p>
            </div>
          </div>
          <div className="w-auto h-full flex content-start justify-self-end mr-8">
            <h1 className="text-black font-medium">€{product.price}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartRow;
