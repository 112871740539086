import React, { useContext, useEffect, useState } from "react";
import { PaymentContext } from "../../context";
import { TransactionStatusEnum } from "../../enums/transactionStatus/TransactionStatusEnum";
import FailureOutput from "../transaction-recap/failure/FailureOutput";
import OngoingOutput from "../transaction-recap/ongoing/OngoingOutput";
import SuccessOutput from "../transaction-recap/success/SuccessOutput";
import TransactionRecap from "../transaction-recap/TransactionRecap";
import FlexContainer from "../theme/FlexContainer"


const TransactionStatus: React.FC = () => {
  const [showRecap, setShowRecap] = useState<boolean>(false);
  const { status } = useContext(PaymentContext);

  function loaderTimeout() {
    setTimeout(() => {
      setShowRecap(true);
    }, 2000);
  }

  useEffect(() => {
    loaderTimeout();
    // eslint-disable-next-line
  }, []);

  return (
    <FlexContainer>
      <div className="flex-col space-y-2 w-11/12 md:w-auto">
        {status === TransactionStatusEnum.SUCCESS ? (
          <SuccessOutput />
          ) : status === TransactionStatusEnum.FAILURE ? (
            <FailureOutput />
          ) : (
            <OngoingOutput />
        )}
        {showRecap && <TransactionRecap />}
      </div>
    </FlexContainer>
  );
};

export default TransactionStatus;
