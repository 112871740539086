import axios from "axios";
import { CONVERT_URL } from "../environment/environment";

export const ConversionService = {
  async convert(from: string, to: string, amount: number): Promise<number> {
    let result = 0;
    await axios
      .get(`${CONVERT_URL}${to}/market_chart?vs_currency=${from}&days=0`)
      .then((res) => {
        const conversionRate = res.data.prices[0][1];
        result = amount / conversionRate;
      });
    return result;
  },
  formatPrice(price: number): number {
    return parseFloat(
      new Intl.NumberFormat("en-EN", { maximumFractionDigits: 5 }).format(price)
    );
  },
  formatPrice18(price: number) : number {
    return parseFloat(
      new Intl.NumberFormat("en-EN", { maximumFractionDigits: 18 }).format(price)
    );
  }
};
