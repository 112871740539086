import React from "react";
import { ReactNode, ReactPortal } from "react";
import { VisibilityContext } from "..";

type VisibilityProviderProps = {
  initialState?: boolean;
  children?: ReactNode | ReactPortal;
};

const VisibilityProvider = (props: VisibilityProviderProps) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [leadingText, setLeadingText] = React.useState<string>("");
  const [bodyText, setBodyText] = React.useState<string>("");
  return (
    <VisibilityContext.Provider
      value={{
        show,
        setShow,
        isLoading,
        setIsLoading,
        error,
        setError,
        leadingText,
        setLeadingText,
        bodyText,
        setBodyText,
      }}
    >
      {props?.children}
    </VisibilityContext.Provider>
  );
};

export default VisibilityProvider;
