import React from "react";
import MoonLoader from "react-spinners/MoonLoader";
import FlexContainer from "../theme/FlexContainer";
import { ThemeContext } from "../../context";

type LoaderProps = {
  loading: boolean;
  message?: string;
  secondaryMessage?: string;
};

const messageStyling: React.CSSProperties = {
  whiteSpace: "pre-wrap",
};

const CheckoutLoader = (props: LoaderProps) => {
  const { loading, message, secondaryMessage } = props;
  const { theme } = React.useContext(ThemeContext);

  return (
    <FlexContainer>
      <div className="shadow-xl bg-gray-50 rounded-lg p-8 flex flex-col space-y-4 items-center">
        <MoonLoader
          loading={loading}
          color={theme === "dark" ? "#C4B6B6" : "#1F4068"}
        />
        <div className="w-auto flex flex-col items-center mb-5">
          <h1 style={messageStyling}>{message ? message : "Please wait..."}</h1>
          <h1 style={messageStyling}>{secondaryMessage && secondaryMessage}</h1>
        </div>
      </div>
    </FlexContainer>
  );
};

export default CheckoutLoader;
